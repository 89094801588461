import { ChainId, Token } from '@pancakeswap/sdk'
// 수정
export const CAKE_MAINNET = new Token(
  ChainId.BSC,
  '0x90f193b165159345E0E61703Eb6A25480213480f',
  18,
  'FOC',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0xFa60D973F7642B748046464e165A65B7323b0DEE',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE_UNISEPOLIA = new Token(
  ChainId.UNISEPOLIA,
  '0x697A539Ef37aC83980af8e59b5055f11d7dFc964',
  18,
  'CAKE',
  'PancakeSwap Token',
  'https://pancakeswap.finance/',
)

export const CAKE_SAIGON = new Token(
  ChainId.SAIGON,
  '0xcBff9d27af2e13Df19C9082276764426DC50eFF7',
  18,
  'FOC',
  'Free Token',
  'https://pancakeswap.finance/',
)



export const CAKE_KLAYTN = new Token(
  ChainId.KLAYTN,
  '0x044925B7c6AA8EF03Ad294Bc48EDC1a2c3877a6D',
  18,
  'FOC',
  'Free Token',
  'https://pancakeswap.finance/',
)



export const CAKE_BASE = new Token(
  ChainId.BASE,
  '0xDA11e185fF18674A633441Ca954D0f55289Cf7E7',
  18,
  'FOC',
  'Free Token',
  'https://pancakeswap.finance/',
)

export const USDC_BSC = new Token(
  ChainId.BSC,
  '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_TESTNET = new Token(
  ChainId.BSC_TESTNET,
  '0x64544969ed7EBf5f083679233325356EbE738930',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_COREMAINNET = new Token(
  ChainId.COREMAINNET,
  '0xa4151B2B3e269645181dCcF2D426cE75fcbDeca9',
  18,
  'USDC',
  'Binance-Peg USD Coin',
  'https://www.centre.io/usdc',
)

export const USDC_SAIGON = new Token(
  ChainId.SAIGON,
  '0x22Ca52E4A56f7CF58AB0F87B9Fa10663844a00CC',
  18,
  'USDC',
  'USD Coin',
  'https://www.centre.io/usdc',
)


export const USDC_BASE = new Token(
  ChainId.BASE,
  '0xd9aAEc86B65D86f6A7B5B1b0c42FFA531710b6CA',
  6,
  'USDbC',
  'Bridged USDC',
  'https://www.centre.io/usdc',
)

export const USDC_ETH = new Token(ChainId.ETHEREUM, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD Coin')

export const USDC_RINKEBY = new Token(
  ChainId.RINKEBY,
  '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b',
  6,
  'tUSDC',
  'test USD Coin',
)

export const USDC_UNISEPOLIA = new Token(
  ChainId.UNISEPOLIA,
  '0x31d0220469e10c4E71834a79b1f276d740d3768F',
  6,
  'USDC',
  'USD Coin',
)

export const USDT_BSC = new Token(
  ChainId.BSC,
  '0x55d398326f99059fF775485246999027B3197955',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_SAIGON = new Token(
  ChainId.SAIGON,
  '0x1a29215512f7eD3994C3B335F67b03E70890ca57',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_COREMAINNET = new Token(
  ChainId.COREMAINNET,
  '0x900101d06A7426441Ae63e9AB3B9b0F63Be145F1',
  18,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const USDT_KLAYTN = new Token(
  ChainId.KLAYTN,
  '0xceE8FAF64bB97a73bb51E115Aa89C17FfA8dD167',
  6,
  'oUSDT',
  'Tether USD',
  'https://tether.to/',
)


export const USDT_ETH = new Token(
  ChainId.ETHEREUM,
  '0xdAC17F958D2ee523a2206206994597C13D831ec7',
  6,
  'USDT',
  'Tether USD',
  'https://tether.to/',
)

export const BUSD_BSC = new Token(
  ChainId.BSC,
  '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  18,
  'BUSD',
  'Binance USD',
  'https://www.paxos.com/busd/',
)



export const CAKE = {
  [ChainId.BSC]: CAKE_MAINNET,
  [ChainId.BSC_TESTNET]: CAKE_TESTNET,
  [ChainId.UNISEPOLIA]: CAKE_UNISEPOLIA,
  [ChainId.KLAYTN]: CAKE_KLAYTN,
  [ChainId.SAIGON]: CAKE_SAIGON,
  [ChainId.BASE]: CAKE_BASE,
}

export const USDC = {
  [ChainId.BSC]: USDC_BSC,
  [ChainId.BSC_TESTNET]: USDC_TESTNET,
  [ChainId.ETHEREUM]: USDC_ETH,
  [ChainId.RINKEBY]: USDC_RINKEBY,
  [ChainId.UNISEPOLIA]: USDC_UNISEPOLIA,
  [ChainId.COREMAINNET]: USDC_COREMAINNET,
  [ChainId.SAIGON]: USDC_SAIGON,
  [ChainId.BASE]: USDC_BASE,
}

export const USDT = {
  [ChainId.BSC]: USDT_BSC,
  [ChainId.ETHEREUM]: USDT_ETH,
  [ChainId.UNISEPOLIA]: USDC_UNISEPOLIA,
  [ChainId.COREMAINNET]: USDT_COREMAINNET,
  [ChainId.KLAYTN]: USDT_KLAYTN,
  [ChainId.SAIGON]: USDT_SAIGON,
}
