import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
import { Token } from "./entities/token";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["ETHEREUM"] = 1] = "ETHEREUM";
    ChainId[ChainId["RINKEBY"] = 4] = "RINKEBY";
    ChainId[ChainId[// GOERLI = 5,
    "BSC"] = 56] = "BSC";
    ChainId[ChainId["UNISEPOLIA"] = 1301] = "UNISEPOLIA";
    ChainId[ChainId["COREMAINNET"] = 1116] = "COREMAINNET";
    ChainId[ChainId["BSC_TESTNET"] = 97] = "BSC_TESTNET";
    ChainId[ChainId["KLAYTN"] = 8217] = "KLAYTN";
    ChainId[ChainId["SAIGON"] = 2021] = "SAIGON";
    ChainId[ChainId["BASE"] = 8453] = "BASE";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS = "0x666666668deab6b4a627c97b1fbac629d2da4795";
// // TODO: ETH This is test version, do not depends on it
var FACTORY_ADDRESS_ETH = "0xD93801d7D3a368D94A3A32E97A20f7aC1948a5dB";
var _obj;
//수정
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.ETHEREUM, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.RINKEBY, FACTORY_ADDRESS_ETH), _define_property(_obj, ChainId.UNISEPOLIA, "0x666666668deab6b4a627c97b1fbac629d2da4795"), _define_property(_obj, ChainId.BSC, FACTORY_ADDRESS), _define_property(_obj, ChainId.BSC_TESTNET, "0x6725f303b657a9451d8ba641348b6761a6cc7a17"), _define_property(_obj, ChainId.COREMAINNET, "0x666666668deab6b4a627c97b1fbac629d2da4795"), _define_property(_obj, ChainId.KLAYTN, "0xef2E94DDB76e128B1aB74a7433d26879A1a15f75"), _define_property(_obj, ChainId.SAIGON, "0x8498E2d7a9d579fAB3AA1056F18A863e789b6321"), _define_property(_obj, ChainId.BASE, "0xc1EB2Cdf635D0Aa7B753268bA3B642Ce2f561957"), _obj);
export var INIT_CODE_HASH = "0xcd45e09ac581cd9fab1a7c5f1a5e6754075cbeddd54e227ca13f5b85529c6360";
var INIT_CODE_HASH_ETH = "0x57224589c67f3f30a6b0d7a1b54cf3153ab84563bc609ef41dfb34f8b2974d2d";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.ETHEREUM, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.RINKEBY, INIT_CODE_HASH_ETH), _define_property(_obj1, ChainId.UNISEPOLIA, "0xcd45e09ac581cd9fab1a7c5f1a5e6754075cbeddd54e227ca13f5b85529c6360"), _define_property(_obj1, ChainId.BSC, INIT_CODE_HASH), _define_property(_obj1, ChainId.BSC_TESTNET, "0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66"), _define_property(_obj1, ChainId.COREMAINNET, "0xa9f2448b88ead94ba495a8a39a1ed21efb6351d319157534007c9ee410e406aa"), _define_property(_obj1, ChainId.KLAYTN, "0x29bd6d25b5a86f6ac94daf96ae9932a8109dee0e95aff78c7c9d38ee24fd6f88"), _define_property(_obj1, ChainId.SAIGON, "0x50368cf9d6b394e5bf92ece698ed924a1d0dd04b6e8d6bbc50020afa3e460bed"), _define_property(_obj1, ChainId.BASE, "0x50368cf9d6b394e5bf92ece698ed924a1d0dd04b6e8d6bbc50020afa3e460bed"), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var _9975 = JSBI.BigInt(9975);
export var _10000 = JSBI.BigInt(10000);
export var MaxUint256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
var _obj3;
export var WETH9 = (_obj3 = {}, _define_property(_obj3, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.RINKEBY, new Token(ChainId.RINKEBY, "0xc778417E063141139Fce010982780140Aa0cD5Ab", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.UNISEPOLIA, new Token(ChainId.UNISEPOLIA, "0x4200000000000000000000000000000000000006", 18, "WETH", "Wrapped Ether", "https://weth.io")), _define_property(_obj3, ChainId.COREMAINNET, new Token(ChainId.COREMAINNET, "0x40375C92d9FAf44d2f9db9Bd9ba41a3317a2404f", 18, "WCORE", "Wrapped CORE", "https://weth.io")), _define_property(_obj3, ChainId.KLAYTN, new Token(ChainId.KLAYTN, "0x19aac5f612f524b754ca7e7c41cbfa2e981a4432", 18, "WKLAY", "Wrapped KLAY", "https://weth.io")), _define_property(_obj3, ChainId.SAIGON, new Token(ChainId.SAIGON, "0xa959726154953bae111746e265e6d754f48570e6", 18, "WRON", "Wrapped RON", "https://weth.io")), _define_property(_obj3, ChainId.BASE, new Token(ChainId.BASE, "0x4200000000000000000000000000000000000006", 18, "WETH", "Wrapped Ether", "https://weth.io")), _obj3);
var _obj4;
export var WBNB = (_obj4 = {}, _define_property(_obj4, ChainId.ETHEREUM, new Token(ChainId.ETHEREUM, "0x418D75f65a02b3D53B2418FB8E1fe493759c7605", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj4, ChainId.BSC, new Token(ChainId.BSC, "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _obj4);
var _obj5;
export var WNATIVE = (_obj5 = {}, _define_property(_obj5, ChainId.ETHEREUM, WETH9[ChainId.ETHEREUM]), _define_property(_obj5, ChainId.RINKEBY, WETH9[ChainId.RINKEBY]), _define_property(_obj5, ChainId.UNISEPOLIA, WETH9[ChainId.UNISEPOLIA]), _define_property(_obj5, ChainId.BSC, WBNB[ChainId.BSC]), _define_property(_obj5, ChainId.COREMAINNET, WETH9[ChainId.COREMAINNET]), _define_property(_obj5, ChainId.KLAYTN, WETH9[ChainId.KLAYTN]), _define_property(_obj5, ChainId.SAIGON, WETH9[ChainId.SAIGON]), _define_property(_obj5, ChainId.BASE, WETH9[ChainId.BASE]), _obj5);
var _obj6;
export var NATIVE = (_obj6 = {}, _define_property(_obj6, ChainId.ETHEREUM, {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj6, ChainId.RINKEBY, {
    name: "Rinkeby Ether",
    symbol: "RIN",
    decimals: 18
}), _define_property(_obj6, ChainId.UNISEPOLIA, {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj6, ChainId.COREMAINNET, {
    name: "CORE",
    symbol: "CORE",
    decimals: 18
}), _define_property(_obj6, ChainId.KLAYTN, {
    name: "Klay",
    symbol: "KLAY",
    decimals: 18
}), _define_property(_obj6, ChainId.SAIGON, {
    name: "Saigon Ron",
    symbol: "RON",
    decimals: 18
}), _define_property(_obj6, ChainId.BASE, {
    name: "Ether",
    symbol: "ETH",
    decimals: 18
}), _define_property(_obj6, ChainId.BSC, {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
}), _define_property(_obj6, ChainId.BSC_TESTNET, {
    name: "Binance Chain Native Token",
    symbol: "tBNB",
    decimals: 18
}), _obj6);
