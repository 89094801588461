import { StaticJsonRpcProvider } from '@ethersproject/providers'

export const BSC_PROD_NODE = process.env.NEXT_PUBLIC_NODE_PRODUCTION || 'https://bsc.nodereal.io'

export const bscRpcProvider = new StaticJsonRpcProvider(BSC_PROD_NODE)

export const coreMainnetnode = 'https://rpc.coredao.org/'


// export const Saigonprodnode = 'https://saigon-testnet.roninchain.com/rpc'


export const uniSepolianode = 'https://sepolia.unichain.org/'

export const coreMainnetRpcProvider = new StaticJsonRpcProvider(coreMainnetnode)

// export const saigonRpcProvider = new StaticJsonRpcProvider(Saigonprodnode)


export const uniSepoliaRpcProvider = new StaticJsonRpcProvider(uniSepolianode)

export default null
