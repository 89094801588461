import { ChainId, Token, WETH9 } from '@pancakeswap/sdk'
import { USDC_COREMAINNET } from './common'

export const coreMainnetTokens = {
  weth: WETH9[ChainId.COREMAINNET],
  usdc: USDC_COREMAINNET,
  cid: new Token(
    ChainId.COREMAINNET,
    '0x000000000e1d682cc39abe9b32285fdea1255374',
    18,
    'CORE',
    'CORE',
    'https://www.binance.com/',
  )
}

