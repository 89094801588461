import { ChainId } from '@pancakeswap/sdk'

export const SUPPORT_ONLY_BSC = [ChainId.BSC]
export const SUPPORT_FARMS = [
  // ChainId.BSC,
  ChainId.UNISEPOLIA,
  // ChainId.GOERLI,
  ChainId.COREMAINNET,
  // ChainId.KLAYTN,
  // ChainId.SAIGON,
  // ChainId.BASE,
]

export const SUPPORT_ZAP = []
